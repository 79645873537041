@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @include mixins.desktop-xlarge {
    justify-content: space-between;
    flex-direction: unset;
  }
}

.employerBack {
  background: #c8d0f2;
}

.jobseekerBack {
  background: #ffc2d4;
}

.layoutForm {
  min-height: 100vh;
  background-color: colors.$grey-violet-050;
  flex-basis: 100%;
  padding: 0 32px;
}

.layoutFormHeader {
  text-align: center;
}

.layoutFormHeaderLogo {
  margin-top: variables.$margin-48;
  margin-bottom: variables.$margin-48;
  cursor: default;
  @media screen and (min-width: 1200px) {
    margin: variables.$margin-48 0;
  }
}

.layoutFormHeaderError {
  margin: 0 variables.$margin-20 variables.$margin-20;
  display: flex;
  justify-content: center;
}

.layoutFormHeaderErrorAlert {
  max-width: 560px;
}

.wrapAuthHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapAuthHeaderWidth {
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .layoutFormHeaderTitle {
      display: flex;
      justify-content: center;
      padding-top: 0;
      text-align: center;
      padding-bottom: variables.$padding-24;
      color: colors.$grey-warm-600;

      @media screen and (min-width: 1200px) {
        padding-top: variables.$padding-32;
        padding-bottom: variables.$padding-32;
      }
    }

  }
}

.layoutFormHeaderSection {
  display: flex;
  justify-content: center;

  > * {
    flex-grow: 1;
  }
}

.layoutLinkContainer {
  margin-right: variables.$margin-8;
  display: flex;
  justify-content: center;
  width: max-content;
}

.layoutLink {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid colors.$grey-warm-200;
  background: colors.$white;
  border-radius: 8px;
  padding: variables.$padding-12 variables.$padding-20;
  z-index: 1;

  @media screen and (min-width: 1200px) {
    position: absolute;
    bottom: unset;
    left: unset;
    top: 32px;
    right: 32px;
    width: auto;
    background: colors.$white;
    padding: variables.$padding-24 variables.$padding-32;
    border-radius: 8px;

    &-info {
      color: colors.$grey-warm-600;
      margin-right: variables.$margin-20;
    }
  }
}

.layoutLinkInfo {
  color: colors.$grey-warm-600;
  margin-right: variables.$margin-12;
}

.layoutLanguageContainer {
  display: flex;
  justify-content: space-between;
}

.layoutImgWrapper {
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-basis: 50%;
    height: 100%;
    max-width: 1200px;
    width: auto;
  }
}
