@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';

.compWrap {
  max-width: 560px;
  padding: variables.$padding-36;
  border: 1px solid colors.$white;
  box-sizing: border-box;
  box-shadow: 0 4px 32px rgba(77, 81, 146, 0.15);
  border-radius: 4px;
  background: colors.$white;
}

.header {
  color: colors.$grey-warm-600;
  text-align: center;
  margin-bottom: variables.$margin-28;
}

.title {
  color: colors.$grey-warm-600;
}

.subtitle {
  color: colors.$grey-warm-600;
}

.btn {
  color: colors.$secondary-300;
  cursor: pointer;
}

.content {
  text-align: center;
}
