@use '../../../styles/variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.languageDropdown {
  padding: variables.$padding-12 0;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  @include mixins.desktop-medium {
    padding: variables.$padding-8;
  }

  & > svg {
    flex-shrink: 0;
  }

  &:hover {
    background-color: colors.$grey-violet-100;
  }
}

.dropdownCurrLang {
  display: flex;
  flex-shrink: 0;
  margin-right: variables.$margin-8;

  img {
    width: 20px;
    height: 15px;
  }
}

.dropdownLine {
  margin: 0 variables.$margin-8;
  width: 1px;
  height: 24px;
  background-color: colors.$grey-violet-200;
}

.langOptions {
  background-color: colors.$white;
  border-right: none;
  margin: variables.$margin-8 auto;
}

.langOption {
  background-color: colors.$white;
  color: colors.$grey-warm-600;
  height: 42px;
  min-height: 42px;
  margin-bottom: 0 !important;
  padding: variables.$padding-12 variables.$padding-16;

  &:hover {
    background-color: colors.$grey-violet-200;
    color: colors.$grey-warm-600 !important;
  }

  &:active {
    background-color: colors.$grey-violet-400 !important;
  }

  &:visited {
    background-color: colors.$grey-violet-400 !important;
  }
}

.langOptionItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > div {
    margin-right: variables.$margin-8 !important;
  }

  span {
    margin-left: variables.$margin-8;
  }

  img {
    width: 20px;
    height: 15px;
  }
}

.dropdownOverlay {
  position: fixed;
  background: colors.$white;
  border: 1px solid colors.$grey-violet-200;
  box-shadow: 0 0 16px -4px rgba(223, 224, 233, 0.6);
  border-radius: 8px;
}

//overwrite dropdown from ant
.language-toggle-menu {
  :global {
    .ant-menu-item-selected {
      background-color: colors.$grey-violet-200 !important;
      color: colors.$grey-warm-500 !important;
    }
  }
}
