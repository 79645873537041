@use '../../../../styles/variables.scss';
@use '../../../../styles/colors.scss';

.compWrap {
  text-align: initial;
  margin-left: 0;
  margin-right: 0;
}

.footer {
  display: block;
  padding-top: variables.$padding-28;
  border-top: 1px solid colors.$grey-violet-300;
  text-align: center;
}
